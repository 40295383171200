<template>
    <footer id="footer">
        <div class="container">
            <div class="column one">
            </div>
        </div>
        <div class="copyright">
            A
            <div class="logo">
                <img :src="logo" alt="Varestørm logo">
                <h1>Varestørm</h1>
            </div>
            product
        </div>
    </footer>
</template>
<script>
import logo from "@/assets/images/logo_small.png"

export default {
    name: "Footer",
    data:() => ({
        logo: logo,
    })
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/components/Footer"
</style>
