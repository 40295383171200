import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {ApolloClient} from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'

Vue.use(VueApollo)

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: (process.env.VUE_APP_APOLLO_HOST ?? `http://${location.hostname}:2565/graphql`)
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
})
const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})

export default apolloProvider
