<template>
    <font-awesome-icon :icon="[family, icon]" :spin="spin" />
</template>
<script>
export default {
    name: "Icon",
    props: {
        icon: {
            type: String,
            default: 'circle'
        },
        family: {
            type: String,
            default: 'far'
        },
        spin: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
</style>
