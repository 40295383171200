import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        players: [],
        restart: null
    },
    getters: {
        players: state => {
            return state.players
        },
        restart: state => {
            return state.restart
        }
    },
    mutations: {
        setPlayers: (state, payload) => {
            state.players = payload
        },
        setRestart: (state, payload) => {
            state.restart = payload
        }
    },
    actions: {},
    modules: {}
})
