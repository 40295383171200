<template>
    <div id="navigation" :class="`${show? 'open' : 'closed'} ${scroll? 'scroll' : 'top'}`" @scroll="onScroll">
        <div class="alert" v-if="restartSoon">
            <icon icon="diamond-exclamation"/>
            <span>{{ restartStatus }}</span>
        </div>
        <div class="container">
            <div class="branding">
                <div class="image">
                    <router-link to="/">
                        <img :src="logo" alt="Varestørm logo">
                        <div class="text">
                            <h1>The Varestørm server</h1>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="toggle-nav">
                <button :class="show? 'open' : 'closed'" @click="show = !show">
                    <icon :icon="show? 'xmark' : 'bars'"/>
                </button>
            </div>
        </div>
        <div class="side-bar" :class="show? 'show' : 'hide'">
            <nav>
                <button :class="show? 'open' : 'closed'" @click="show = false">
                    <icon :icon="show? 'xmark' : 'arrow-right'"/>
                </button>
                <ul>
                    <li>
                        <router-link to="/">
                            <icon icon="home"/>
                            <span>Home</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/leaderboard">
                            <icon icon="trophy"/>
                            <span>Leaderboard</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/rules">
                            <icon icon="book"/>
                            <span>Rules</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="https://map.varestorm.com" target="_blank" class="button">
                            <icon icon="compass"/>
                            <span>The World Map</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
<script>
import logo from "@/assets/images/logo_small.png"
import Icon from "@/components/Icon";

export default {
    name: "Navigation",
    components: {Icon},
    data: () => ({
        logo: logo,
        show: false,
        scroll: false,
        now: new Date(),
        interval: null
    }),
    created() {
        this.interval = setInterval(this.tick, 1000)
        window.addEventListener('scroll', this.onScroll)

    },
    beforeDestroy() {
        clearInterval(this.interval)
        window.removeEventListener('scroll', this.onScroll)

    },
    watch: {
        restart() {
            this.tick();
        },
        $route: {
            handler() {
                this.show = false
            }
        }
    },
    computed: {
        restart() {
            return this.$store.getters?.restart ?? null
        },
        restartDate() {
            return new Date(this.restart ?? null)
        },
        restartSoon() {
            if (this.milliseconds < 0) {
                return false
            }
            if (this.hours <= 0) {
                return (this.minutes < 10)
            }
            return false
        },
        restartStatus() {
            if (this.minutes < 1) {
                return `Restarting in ${this.seconds} second${this.seconds === 1? '' : 's'}`
            }
            if (this.minutes < 10) {
                return `Restarting in ${this.minutes} minute${this.minutes === 1? '' : 's'}`
            }
        },
        hours() {
            return Math.floor(((this.milliseconds / (1000 * 3600)) % 60))
        },
        minutes() {
            return Math.floor(((this.milliseconds / (1000 * 60)) % 60))
        },
        seconds() {
            return Math.floor(((this.milliseconds / (1000)) % 60))
        },
        milliseconds() {
            return this.restartDate.getTime() - this.now.getTime()
        },
    },
    methods: {
        tick() {
            this.now = new Date()
        },
        onScroll() {
            this.scroll = (window.scrollY > 150)
        }
    }
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/components/Navigation"
</style>
