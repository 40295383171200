<template>
    <div id="app">
        <navigation/>
        <div id="page">
            <router-view/>
            <Footer/>
        </div>
    </div>
</template>
<script>
import logo from "@/assets/images/logo.png"
import background from "@/assets/images/background.png"
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
    name: "App",
    components: {Footer, Navigation},
    data: () => ({
        image: background,
        logo: logo,
        loaded: false
    }),
    beforeCreate() {
        const d = new Date();
        if (d.getMonth() + 11 && d.getDay() > 20) {
            import("@/assets/snow")
        }
    },
    created() {
        setTimeout(this.onLoaded, 1000)
    },
    watch: {
        $route: {
            handler(route) {
                console.log(route)
                document.title = `${route.name ?? 'Hello'} | Welcome to the Varestørm Server`
            },
            immediate: true
        }
    },
    methods: {
        onLoaded(loaded = true) {
            this.loaded = loaded
        }
    }
}
</script>
<style lang="sass">
</style>
